import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './profile.scss';
import {
   useGetUserDataQuery, useLazyGetAllUserProfilesQuery,
} from '../../../store/user/user.api';
import ProfileInfo from '../../profile/profile-info';
import { IS_ADMIN } from '../../../utils/role';
import ProfileUsers from '../../profile/profile-users';

const Profile = () => {
   const [translate] = useTranslation(['common']);
   const { data: userData } = useGetUserDataQuery();
   const [trigger, { data: users }] = useLazyGetAllUserProfilesQuery();

   const isAdmin = IS_ADMIN();

   useEffect(() => {
      isAdmin && trigger();
   }, [isAdmin]);

   return (
      <div className={'m-3'}>
         <h3 className={'mb-3'}>{translate('common:profile.profile')}</h3>
         {userData && <ProfileInfo userData={userData}></ProfileInfo>}
         {isAdmin && users &&
            <div className={'users'}>
               <ProfileUsers users={users} />
            </div>
         }
      </div>
   );
};

export default Profile;
